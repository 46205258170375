<template>
  <face-container>
    <face-index ref="faceIndexRef" :isTest="false" @on-send-face="getFaceData"></face-index>
    <template #action>
      <!-- 开始匹配 -->
      <a-button
        block
        size="large"
        type="primary"
        @click="start"
        v-if="state.status === 1"
      >{{ $t('StartMatching') }}</a-button>
      <!-- 匹配中 -->
      <a-button
        block
        size="large"
        type="primary"
        v-else-if="state.status === 2"
      >{{ $t('Matching') }}...</a-button>
      <!-- 登录中 -->
      <a-button
        block
        size="large"
        type="primary"
        :loading="state.loading"
        v-else-if="state.status === 3"
      >{{ $t('XB_LoginInProgress') }}</a-button>
    </template>
  </face-container>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, getCurrentInstance, onUnmounted, reactive } from "vue";
import faceIndex from "@/components/faceCapture/index.vue";
import faceContainer from "@/components/faceCapture/container.vue";
import { LoginByFace } from '@/api/user';
import { currentHost } from "@/utils/request.js";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const emit = defineEmits(["emit"]);

const state = reactive({
  status: 1, // 开始-1 匹配中-2 登录中-3
  loading: false,
});
const faceIndexRef = ref(null);
function start() {
  state.status = 2;
  faceIndexRef.value.openCamera();
}
async function getFaceData(data) {
  if (state.loading) return false;
  state.loading = true;
  state.status = 3;
  const res = await LoginByFace({
    fileBase: data.base64,
    site: currentHost,
    platform: 1,
    loginMode: 3,
  });
  if (res.ret === 0) {
    emit('on-success', res);
  } else {
    // proxy.$message.error(res.msg);
  }
  state.loading = false;
  faceIndexRef.value.stopCamera();
  state.status = 1;
};
</script>
<style lang="less" scoped>
</style>
